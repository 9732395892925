import React from "react";
import heroA from "../assets/heroA.jpg";
import { Link } from "react-router-dom";
import { motion, AnimatePresence, useAnimation } from "framer-motion";

const Hero = () => {
  return (
    <div className="-mt-20 md:-mt-20">
      {/* Hero */}
      <div className="relative overflow-hidden">
        <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10"
        >
          <motion.div
            className="top-[22rem] md:top-[28rem] max-w-3xl text-start mx-auto relative z-10"
            initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay:1.5}}
            
          >
            <h1 className="block text-3xl ml-5 md:text-6xl font-black tracking-wider text-white sm:text-4xl dark:text-white">
              Face Your Skin <br />
              With Us
            </h1>
            <Link to="">
              <button
                type="button"
                className="py-4 px-8 ml-5 mt-5 transition-all duration-500 inline-flex items-center gap-x-2 text-lg font-bold rounded-lg border border-[#d8d5d3] text-[white] hover:bg-[#9B9590] disabled:opacity-50 disabled:pointer-events-none "
              >
                Consult Now
              </button>{" "}
            </Link>
          </motion.div>

          <div className="relative max-w-6xl mx-auto">
            <div
              className="w-full  object-cover h-96 sm:h-[580px] bg-no-repeat bg-center bg-cover rounded-xl blur-load"
              style={{ backgroundImage: `url(${heroA})` }}
            />
            <div className="absolute inset-0 bg-black opacity-30 rounded-xl"></div>
            <div className="absolute inset-0 w-full h-full"></div>
            <div className="hidden lg:block"></div>
          </div>
        </div>
      </div>
      {/* End Hero */}
    </div>
  );
};

export default Hero;
