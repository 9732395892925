// Backend Backups
const localHost = "http://127.0.0.1:8000/"
const gcloud = "https://backend.gvieaesthetic.com/"

// CURRENT SERVING BACKEND
const currentApi = gcloud
// CURRENT SERVING BACKEND


export {
    currentApi,
}