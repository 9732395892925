import React from 'react'
import Features from '../components/Features'


const ServicePage = () => {
  return (
    <>
    <div className='mt-40 mx-auto max-w-[75rem] '>
    <p className="text-center text-3xl ml-5 md:text-6xl font-black tracking-wider text-black sm:text-4xl dark:text-white" >Our Services </p>
    <p className='mt-10 text-2md text-gray-600 dark:text-gray-200 leading-loose'>Our dedication to delivering the best services is at the core of who we are. We're committed to consistently providing top-notch quality, reliability, and efficiency to meet and exceed our clients' expectations. It's not just about meeting contractual obligations; it's about tailoring our services to the unique needs of each client. We prioritize transparent communication and view challenges as opportunities to showcase our commitment to client satisfaction, always striving for swift and effective solutions. We don't just promise excellence; we embody it, actively seeking feedback and continuously improving to stay ahead in our client-centric approach.</p>
    <Features />
    </div>
    </>
  )
}

export default ServicePage