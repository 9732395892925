import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { currentApi } from "../keys/backend";


const swal = require("sweetalert2");

// http://127.0.0.1:8000/api/appointment/consultation/post/
const Consultation = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    user_preference_date: "",
    user_preference_time: "",
    details: "",
    phone: "",
  });

  const [loading, setLoading] = useState(false);
  const [hideForm, setHideForm] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${currentApi}api/appointment/consultation/post/`,
        formData
      );
      if (response.status === 201) {
        // Contact created successfully
        setLoading(false);
        console.log("Contact created successfully");
        console.log(formData)
        setHideForm(true);
        navigate("/");
        swal.fire({
          title: "Contact Successful",
          icon: "success",
          toast: true,
          timer: 5000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error("Error:", error.response.data);
        setLoading(false);
        swal.fire({
          title: "Please try again",
          icon: "error",
          toast: true,
          timer: 5000,
          position: "top-right",
          timerProgressBar: false,
          showCloseButton: true,
          showConfirmButton: false,
        });
      } else {
        console.error("Network Error:", error);
        setLoading(false);
        swal.fire({
          title: "Network Error",
          icon: "error",
          toast: true,
          timer: 5000,
          position: "top-right",
          timerProgressBar: false,
          showCloseButton: true,
          showConfirmButton: false,
        });
      }
    }
  };

  return (
    <>
      {/* Hire Us */}
      <div
        className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
      >
        {/* Grid */}

        {hideForm ? (
          <div
            className="bg-teal-50 border-t-2 border-teal-500 rounded-lg p-4 dark:bg-teal-800/30"
            role="alert"
          >
            <div className="flex">
              <div className="flex-shrink-0">
                {/* Icon */}
                <span className="inline-flex justify-center items-center w-8 h-8 rounded-full border-4 border-teal-100 bg-teal-200 text-teal-800 dark:border-teal-900 dark:bg-teal-800 dark:text-teal-400">
                  <svg
                    className="flex-shrink-0 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
                    <path d="m9 12 2 2 4-4" />
                  </svg>
                </span>
                {/* End Icon */}
              </div>
              <div className="ms-3">
                <h3 className="text-gray-800 font-semibold dark:text-white">
                  We Have Received Your Request
                </h3>
                <p className="text-sm text-gray-700 dark:text-gray-400">
                  Our team is working diligently, and we will get back to you
                  shortly. Your patience is highly valued.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="grid md:grid-cols-2 items-center gap-12"
          >
            <div>
              <h1 className="text-3xl font-bold text-gray-800 sm:text-4xl lg:text-5xl lg:leading-tight dark:text-white">
                Book Consultation
              </h1>
              <p className="mt-1 md:text-lg text-gray-800 dark:text-gray-200">
                We are dedicated to enhancing your natural beauty and boosting
                your confidence through personalized skincare solutions.
              </p>
              <div className="mt-8">
                <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                  Why Choose Us?
                </h2>
                <ul className="mt-2 space-y-2">
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 mt-0.5 h-5 w-5 text-gray-600 dark:text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    <span className="text-gray-600 dark:text-gray-400">
                      Experienced Professionals
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 mt-0.5 h-5 w-5 text-gray-600 dark:text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    <span className="text-gray-600 dark:text-gray-400">
                      State-of-the-Art Facilities
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 mt-0.5 h-5 w-5 text-gray-600 dark:text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12" />
                    </svg>
                    <span className="text-gray-600 dark:text-gray-400">
                      Personalized Approach
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Col */}
            <div className="relative">
              {/* Card */}
              <div className="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-10 dark:border-gray-700">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  Fill in the form
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="mt-6 grid gap-4 lg:gap-6">
                    {/* Grid */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                      <div>
                        <label className="block mb-2 text-sm text-gray-700 font-medium dark:text-white">
                          Name*
                        </label>
                        <input
                          type="text"
                          name="name"
                          required
                          id="hs-firstname-hire-us-1"
                          className="py-3 px-4 block w-full border-gray-200 border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                        >
                          Email*
                        </label>
                        <input
                          type="email"
                          name="email"
                          id="hs-email"
                          required
                          className="py-3 px-4 block w-full border-gray-200 rounded-lg border text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {/* End Grid */}
                    <div>
                      <label
                        htmlFor="hs-work-email-hire-us-1"
                        className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                      >
                        Subject*{" "}
                      </label>
                      <input
                        type="text"
                        name="subject"
                        required
                        id="hs-work-email-hire-us-1"
                        className="py-3 px-4 block w-full border-gray-200 rounded-lg border text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="hs-work-email-hire-us-1"
                        className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                      >
                        Phone Number{" "}
                      </label>
                      <input
                        type="text"
                        name="phone"
                        id="hs-work-email-hire-us-1"
                        className="py-3 px-4 block w-full border-gray-200 rounded-lg border text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        onChange={handleChange}
                      />
                    </div>
                    {/* Grid */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                      <div>
                        <label
                          htmlFor="selectDay"
                          className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                        >
                          Select Day*
                        </label>
                        <input
                          id="selectDay"
                          type="date"
                          name="user_preference_date"
                          required
                          className="py-3 px-4 block w-full border-gray-200 rounded-lg border text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                          onChange={handleChange}
                        >
                          
                        </input>
                      </div>
                      <div>
                        <label
                          htmlFor="selectTime"
                          className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                        >
                          Select Time*
                        </label>
                        <select
                          id="selectTime"
                          required
                          name="user_preference_time"
                          className="py-3 px-4 block w-full border-gray-200 rounded-lg border text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                          onChange={handleChange}
                        >
                          <option value="">--None--</option>
                          <option value="morning">Morning</option>
                          <option value="afternoon">Afternoon</option>
                          <option value="evening">Evening</option>
                          {/* Add more times as needed */}
                        </select>
                      </div>
                    </div>
                    {/* End Grid */}
                    <div>
                      <label
                        htmlFor="hs-about-hire-us-1"
                        className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                      >
                        Details*
                      </label>
                      <textarea
                        id="hs-about-hire-us-1"
                        name="details"
                        required
                        rows={4}
                        className="py-3 px-4 block w-full border-gray-200 border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        defaultValue={""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/* End Grid */}

                  <div className="mt-6 grid">
                    {/* Loading Button Start */}

                    {loading ? (
                      <button
                        type="submit"
                        disabled
                        className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-[#A39182] text-white hover:bg-[#997659] transition-all duration-300 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      >
                        <span
                          className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                          role="status"
                          aria-label="loading"
                        />
                        Please Wait...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-[#A39182] text-white hover:bg-[#997659] transition-all duration-300 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                      >
                        Book Now
                      </button>
                    )}

                    {/* Loading Button End */}
                  </div>
                </form>
                <div className="mt-3 text-center">
                  <p className="text-sm text-gray-500">
                    We'll get back to you in 1-2 business days.
                  </p>
                </div>
              </div>
              {/* End Card */}
            </div>
            {/* End Col */}
          </div>
        )}
        {/* End Grid */}
      </div>
      {/* End Hire Us */}
    </>
  );
};

export default Consultation;
