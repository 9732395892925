import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import HomePage from './pages/HomePage';
import ContctPage from './pages/ContctPage';
import ServicePage from './pages/ServicePage';
import ScrollToTop from './components/ScrollToTop';
import ConsultNow from './pages/ConsultNow';
 


function App() {
  return (
    <BrowserRouter>
    <>
    <NavBar />
    <ScrollToTop />
    <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/contact" element={<ContctPage />} />
    <Route path="/services" element={<ServicePage />} />
    <Route path="/consult" element={<ConsultNow />} />
    </Routes>
    <Footer />
    </>
    </BrowserRouter>
  );
}

export default App;
