import React from "react";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Consultation from "../components/Consultation";
import About from "../components/About";

const HomePage = () => {
  return (
  <div>
    <Hero />
    <Features />
    <About />
    <Consultation />
  </div>
  )
};

export default HomePage;
