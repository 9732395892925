import React, { useState, useEffect } from "react";
import axios from "axios";
import btytrt from "../assets/btytrt.png";
import leavesLight from "../assets/leavesLight.png";
import leaves from "../assets/leaves.png";
import preloader from "../assets/preloader.gif"
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Lottie from "lottie-react";
import loader from "../assets/animations/loader.json"
import { currentApi } from "../keys/backend";
const swal = require("sweetalert2");

const Features = () => {
  const [openCards, setOpenCards] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [darkIcons, setDarkIcons] = useState(() => {
    const darkModePreference = localStorage.getItem('darkMode');
    return darkModePreference === 'true';
  });
  // Trigger animation on scroll - Start
  const controls = useAnimation();

  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  // Trigger animation on scroll - End

  const dropdown = (index) => {
    setOpenCards((prevOpenCards) => {
      const newOpenCards = [...prevOpenCards];
      newOpenCards[index] = !newOpenCards[index];
      return newOpenCards;
    });
  };

  useEffect(() => {
    localStorage.setItem('darkMode', darkIcons.toString());
  }, [darkIcons])

  // Get Services - Start
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const res = await axios.get(
          `${currentApi}api/appointment/services/all/`
        );
        setServices(res.data);
        console.log(res.data);
        setLoading(false);
        setOpenCards(Array(res.data.length).fill(false)); // Initialize openCards array
      } catch (err) {
        console.error("Error fetching data:", err); // Log any errors
        swal.fire({
          title: "Something Went Wrong",
          icon: "error",
          toast: true,
          timer: 5000,
          position: "top-right",
          timerProgressBar: false,
          showConfirmButton: false,
        });
      }
    };
    fetchServices();
  }, []);
  // Get Services - End

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0, transition: { delay: 1, duration:1 } });
    }
  }, [controls, inView]);

  return (
    <>
      {/* Icon Blocks */}
      <div
        ref={ref}
        className="w-full md:max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
      >
        <h2 className="font-bold text-xl sm:text-3xl dark:text-gray-100 pb-6">
          Our Services
        </h2>
     {loading ? (<Lottie className="h-40" animationData={loader} loop={true} />) 
     : (<></>)}
        <motion.div
          className="grid sm:grid-cols-2 lg:grid-cols-3 items-center gap-6 md:gap-10"
          initial={{ opacity: 1, y: 100 }}
          animate={controls}
          transition={{ duration: 1 }}
        >
          {" "}
          {/* Card */}
          {services.map((service, index) => (
            <div key={index}>
              <motion.div
                onClick={() => dropdown(index)}
                className="w-full h-full bg-white cursor-pointer shadow-lg rounded-lg p-5 dark:bg-gray-900"
                whileHover={{ scale: 1.05 }}
              >
                <div className="flex flex-row items-center w-full gap-x-4 mb-3">
                  <div className="inline-flex justify-center items-center w-[58px] h-[58px] rounded-full">
                    <img src={"https://res.cloudinary.com/ds0amxua2/"+service.icon} alt="beauty" className="object-contain" />
                  </div>
                  <div className="flex-shrink-0">
                    <h3 className="flex flex-1 text-sm sm:text-lg md:text-xl lg:text-lg font-bold text-gray-800 dark:text-gray-100">
                      {service.title}
                    </h3>
                  </div>
                  <button
                    className="ms-auto"
                    onClick={() => dropdown(index)}
                  >
                    <motion.img
                    onClick={() => dropdown(index)}
                      src={darkIcons ? leavesLight : leaves}
                      alt="Leaves"
                      className="w-10 h-10 animate-bounce"
                      animate={{ rotate: openCards[index] ? 180 : 0 }}
                      transition={{ duration: 0.6 }}
                    />
                  </button>
                </div>
                <>
                  <AnimatePresence>
                    {openCards[index] && (
                      <motion.p
                        key="dropdown-content"
                        className="text-gray-600 dark:text-gray-200"
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.6 }}
                      >
                        {/* List */}
                        <ul
                          role="list"
                          className={`space-y-2 sm:space-y-4 overflow-hidden transition-all duration-1000 ease-in-out ${
                            !openCards[index] // Use !openCards[index] instead of !isOpen
                              ? "opacity-0 max-h-0"
                              : "opacity-100 max-h-full"
                          }`}
                        >
                          {service.info.split(",").map((item, index) => (
                            <li key={index} className="flex space-x-3">
                              {/* Solid Check */}
                              <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                                <svg
                                  className="flex-shrink-0 h-3.5 w-3.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <polyline points="20 6 9 17 4 12" />
                                </svg>
                              </span>
                              {/* End Solid Check */}
                              <span className="text-sm sm:text-base text-gray-500">
                                {item.trim()}{" "}
                                {/* Use trim to remove any leading/trailing whitespaces */}
                              </span>
                            </li>
                          ))}
                        </ul>
                        {/* End List */}
                      </motion.p>
                    )}
                  </AnimatePresence>
                </>
              </motion.div>
            </div>
          ))}
          {/* End Card */}
        </motion.div>
      </div>
      {/* End Icon Blocks */}
    </>
  );
};

export default Features;
